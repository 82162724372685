<template>
  <div>
    <!-- pc端 -->
    <div class="pc">
      <div class="head-bar flex justify-between align-center">
        <div class="lang-box flex">
          <!-- 
          <a class="lang" href="https://www.szcreativeweek.cn">cn</a>
          <a class="lang cur" href="https://www.szcreativeweek.com">en</a>
          -->
        </div>

        <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/en/img/color.png" class="logo" alt="SZCW Shenzhen China International Furniture Exhibition & Creative Week logo" />
        
        <div class="logo-box flex justify-end">
          <div class=" flex justify-end">
            <a href="https://www.xiaohongshu.com/user/profile/5cd27b05000000001700a9b0?xhsshare=CopyLink&appuid=5cd27b05000000001700a9b0&apptime=1698203565"  title="小红书" target="_blank" rel="nofollow noopener noreferrer" class="f-logo">
              <img src="../../assets/img/xhs.jpg" alt="小红书" class="block fill" />
            </a>
            <a href="https://instagram.com/szcreativeweek" title="ins" target="_blank" rel="nofollow noopener noreferrer" class="f-logo">
              <img src="../../assets/img/instagram.jpg" alt="ins" class="block fill" />
            </a>
            <!-- <a title="微信" class="f-logo" href="">
              <img src="../../assets/img/wechat.png" alt="微信" class="block fill" />
            </a> -->
          </div>
          <div class="lang-box flex" style="margin-left: 40px;">
            <a class="lang" href="https://www.szcreativeweek.cn">CN</a>
            <a class="lang cur" href="https://www.szcreativeweek.com">EN</a>
          </div>
        </div>
      </div>
      
      <div class="date text-center text-bold">March 16-19, 2025 | Shenzhen World Exhibition and Convention Center</div>
      
      <div class="menu-bar">
        <div class="menu-bar-content relative">
          <div class="menu-container relative">
            <div class="main-menu flex justify-end text-center" @click="changeMenu($event)">
              <a :class="['menu-item', currentMenu === 'home' ? 'active' : '']" data-key="home" href="/">
                <span>Home</span>
              </a>
              <div :class="['menu-item', currentMenu === 'design' ? 'active' : '']" data-key="design">
                <span>The Fair</span>
                <img src="../../assets/img/ri_arrow-drop-down-line.png" alt="" class="down-icon">
              </div>
              <div :class="['menu-item', currentMenu === 'global' ? 'active' : '']" data-key="global">
                <span>Visitors</span>
                <img src="../../assets/img/ri_arrow-drop-down-line.png" alt="" class="down-icon">
              </div>
              <div :class="['menu-item', currentMenu === 'service' ? 'active' : '']" data-key="service">
                <span>Contact Us</span>
                <img src="../../assets/img/ri_arrow-drop-down-line.png" alt="" class="down-icon">
              </div>
              <div :class="['menu-item', currentMenu === 'exh' ? 'active' : '']" data-key="exh">
                <span>Exhibitors</span>
                <img src="../../assets/img/ri_arrow-drop-down-line.png" alt="" class="down-icon">
              </div>
            </div>
            <div class="sub-menu-box absolute flex justify-end text-center bg-white">
              <div class="sub-menu"></div>
              <div class="sub-menu">
                <a class="sub-menu-item-en" href="/intro">About</a>
                <a class="sub-menu-item-en" href="/theme">Exhibitions</a>
                <a class="sub-menu-item-en" href="/highlights">Previous Editions</a>
                <a class="sub-menu-item-en" href="/organizer">The Organizer</a>
                <!-- <a class="sub-menu-item-en" href="/articles">Articles</a> -->
              </div>
              <div class="sub-menu">
                <a class="sub-menu-item-en" href="/register">Pre-register</a>
                <a class="sub-menu-item-en" href="/group">Group Registration</a>
                <a class="sub-menu-item-en" href="/exhibitor">Exhibitor List</a>
                <a class="sub-menu-item-en" href="/guide">Guide Map</a>
                <a class="sub-menu-item-en" href="/history">Highlights</a>
                <a class="sub-menu-item-en" href="/traffic">Travel & Accommodation</a>
              </div>
              <div class="sub-menu">
                <!-- <a class="sub-menu-item-en" href="">Group Services</a> -->
                <a class="sub-menu-item-en" href="/sourcing">Exhibitor Matchmaking</a>
                <a class="sub-menu-item-en" href="/business">General Inquiry</a>
              </div>
              <div class="sub-menu">
                <a href="https://join.szcreativeweek.com/admin/join" class="sub-menu-item-en" target="_blank" rel="noopener noreferrer">I want to exhibit</a>
                <!-- <a class="sub-menu-item-en" href="/service">Exhibitor Services</a> -->
              </div>
            </div>
          </div>
          
          <div class="search-bar">
            <input type="search" v-model.trim="keywords" placeholder="Please enter your search content" class="search-input" @keyup.enter="toSearch" />
            <!-- <img src="../../assets/img/search.png" alt="" class="search-icon" @click="toSearch"> -->
            <a :href="'/search?keywords=' + encodeURIComponent(keywords)">
              <img src="../../assets/img/search.png" alt="" class="search-icon">
            </a>
          </div>
          
        </div>
      </div>
      
    </div>
    
    <!-- 移动端 -->
    <div class="phone">
      <div class="head-bar-phone flex justify-between">
        <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/en/img/color.png" class="logo" alt="SZCW Shenzhen China International Furniture Exhibition & Creative Week logo" />
        <div class="flex justify-end">
          <div class="lang-box flex">
            <a class="lang" href="https://www.szcreativeweek.cn">CN</a>
            <a class="lang cur" href="https://www.szcreativeweek.com">EN</a>
          </div>
          <div class="menu-icon-box flex justify-end" @click="isShowMenu = true">
            <img src="../../assets/img/menu.png" alt="" class="menu-icon">
          </div>
        </div>
      </div>
      
      <div :class="['navigation-mobile', isShowMenu ? 'is-visible' : '', ]">
        <span class="el-icon-close close-icon" @click="isShowMenu = false"></span>
        <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/en/img/color.png" class="logo" alt="SZCW Shenzhen China International Furniture Exhibition & Creative Week logo" />
        <div class="menu-container">
          <a class="menu-item" href="/">Home</a>
          <el-collapse v-model="currentMenu" accordion @change="handleChange">
            <el-collapse-item title="The Fair" name="design">
              <a class="block" href="/intro">About</a>
              <a class="block" href="/theme">Exhibitions</a>
              <a class="block" href="/highlights">Previous Editions</a>
              <a class="block" href="/organizer">The Organizer</a>
              <a class="block" href="/articles">Articles</a>
            </el-collapse-item>
            <el-collapse-item title="Visitors" name="global">
              <!-- <a class="block" href="/register">Pre-register</a> -->
              <!-- <a class="block" href="/group">Group Registration</a> -->
              <a class="block" href="/exhibitor">Exhibitor List</a>
              <a class="block" href="/guide">Guide Map</a>
              <a class="block" href="/history">Highlights</a>
              <a class="block" href="/traffic">Travel & Accommodation</a>
            </el-collapse-item>
            <el-collapse-item title="Contact Us" name="service">
              <!-- <a class="block" href="">Group Services</a> -->
              <a class="block" href="/sourcing">Exhibitor Matchmaking</a>
              <a class="block" href="/business">General Inquiry</a>
            </el-collapse-item>
            <el-collapse-item title="Exhibitors" name="exh">
              <a class="block" href="https://join.szcreativeweek.com/admin/join" target="_blank" rel="noopener noreferrer">I want to exhibit</a>
              <!-- <a class="block" href="/service">Exhibitor Services</a> -->
            </el-collapse-item>
          </el-collapse>
        </div>
        
        <div class="logo-box flex">
          <a href="https://www.xiaohongshu.com/user/profile/5cd27b05000000001700a9b0?xhsshare=CopyLink&appuid=5cd27b05000000001700a9b0&apptime=1698203565"  title="小红书" target="_blank" rel="noopener noreferrer" class="f-logo">
            <img src="../../assets/img/xhs.jpg" alt="小红书" class="block fill" />
          </a>
          <a href="https://instagram.com/szcreativeweek" title="ins" target="_blank" rel="noopener noreferrer" class="f-logo">
            <img src="../../assets/img/instagram.jpg" alt="ins" class="block fill" />
          </a>
          <!-- <a title="微信" class="f-logo" href="">
            <img src="../../assets/img/wechat.png" alt="微信" class="block fill" />
          </a> -->
        </div>
        
        <!-- <div class="btn-box">
          <a href="https://join.szcreativeweek.com/admin/join" target="_blank" rel="noopener noreferrer" class="btn text-light">I want to exhibit</a>
          <a class="btn text-light" href="https://reg40.szcreativeweek.com/h5/code?l=5C8523-E1A-F9" target="_blank" rel="noopener noreferrer">Pre-register now</a>
        </div> -->
        
      </div>
    </div>
    

  </div>
</template>

<script>
  export default {
    props: {
      active: {
        type: String,
        default: 'home'
      },
      subActive: {
        type: String,
        default: ''
      },
    },
    data() {
      return {
        currentMenu: this.active,
        isShowMenu: false,
        keywords: '',
      }
    },
    created() {
      
    },
    methods: {
      changeMenu(e) {
        // console.log(e.target.dataset.key);
        // this.currentMenu = e.target.dataset.key
      },
      handleChange(val) {
        console.log(val);
      },
      navigateTo(path) {
        // 目标页为当前页时无导航跳转
        if (this.subActive && path.endsWith(this.subActive)) {
          return
        } else {
          this.$router.push(path)
        }
      },
      toSearch() {
        if (this.keywords) {
          this.$router.push({
            path: '/search',
            query: {
              keywords: encodeURIComponent(this.keywords)
            }
          })
        }
      },
    },
  }
</script>

<style scoped lang="less">
  .block {
    display: block;
  }
  
  // 移动端
  @media screen and (max-width: 1023px) {
    .pc {
      display: none;
    }
    .phone {
      display: block;
    }
    
    .head-bar-phone {
      padding: 10px;
    
      .logo {
        width: 160px;
        height: auto;
        margin: 5px;
      }
      
      .menu-icon-box {
        width: 32px;
        height: 32px;
      }
      .menu-icon {
        width: 28px;
        height: 28px;
        padding: 4px;
      }
      
      .lang-box {
        width: 80px;
        height: 24px;
        margin: 10px 10px 0 0;
        
        .lang {
          width: auto;
          height: 14px;
          line-height: 14px;
          font-size: 14px;
          cursor: pointer;
          color: #C4C4C4;
          border-right: 2px solid #777;
          padding: 0 10px;
          font-family: 'Poppins-Bold';
          
          &:last-child {
            border-right: none;
          }
        }
        
        .cur {
          color: #222222;
        }
      }
      
    }
    
    .navigation-mobile {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 3000000000;
      padding: 25px;
      overflow: auto;
      opacity: 0;
      visibility: hidden;
      transform: scale(1.1);
      transition: 0.3s ease-in;
      // background: rgba(0, 0, 0, 0.8);
      background: rgba(255, 255, 255, 0.99);
      box-sizing: border-box;
    }
    .navigation-mobile.is-visible {
      opacity: 1;
      visibility: visible;
      transform: scale(1);
    }
    
    .navigation-mobile {
      
      .logo {
        display: block;
        margin: 0 auto 20px;
        width: 160px;
        height: auto;
      }
      
      /deep/ .el-collapse {
        .el-collapse-item {
          .el-collapse-item__header {
            background-color: transparent;
            color: #060211;
            font-size: 14px;
          }
          
          .el-collapse-item__wrap {
            
            .el-collapse-item__content {
              padding: 10px;
              font-size: 14px;
              color: #060211;
              line-height: 30px;
              
              a {
                color: #060211;
              }
            }
          }
        }
      }
      
      .close-icon {
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 901;
        font-size: 30px;
        color: #060211;
      }
      
      .menu-container {
          
        .menu-item {
          display: block;
          width: 325px;
          color: #060211;
          font-size: 15px;
          line-height: 49px;
          cursor: pointer;
        }
        
      }
      
      .tips {
        margin: 30px auto;
        color: #060211;
        font-size: 16px;
      }
      .logo-box {
        margin-top: 50px;
        
        .f-logo {
          display: block;
          width: 24px;
          height: auto;
          margin: 0 15px 0 0;
          background-color: #fff;
          border-radius: 10px;
          padding: 4px;
        }
        .shipinhao-icon {
          
          .shipinhao-qrcode {
            display: none;
            top: 7px;
            right: -10px;
            z-index: 20;
            width: 50px;
            height: auto;
            
            &:hover {
              display: block;
            }
          }
          
          &:hover {
            .shipinhao-qrcode {
              display: block;
            }
          }
        }
      }
      
      .btn-box {
        padding: 20px 0;
        
        .btn {
          display: block;
          width: 100%;
          line-height: 40px;
          border: none;
          border-radius: 4px;
          background-color: #E4007F;
          font-size: 14px;
          text-align: center;
          // color: #060211;
          color: #fff;
          margin-bottom: 10px;
          box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
        }
        .reg-btn {
          background-color: #00ABA5;
          color: #fff;
        }
        
      }
      
    }
    

  }
  
  // PC端
  @media screen and (min-width: 1024px) {
    
    .pc {
      display: block;
    }
    .phone {
      display: none;
    }
    
    .head-bar {
      position: relative;
      width: 100%;
      padding: 3px 5px;
      line-height: 13.2px;
      font-size: 3.2px;
      border-bottom: 0.1px solid #eee;
      
      .lang-box {
        width: 24px;
        
        .lang {
          display: flex;
          align-items: center;
          justify-content: center;
          // width: 9px;
          height: 4.5px;
          font-size: 4.5px;
          cursor: pointer;
          color: #C4C4C4;
          border-right: 0.5px solid #666;
          padding: 0 3px 0.5px;
          font-family: 'Poppins-bold';
          
          &:last-child {
            border-right: none;
          }
        }
        
        .cur {
          color: #222222;
        }
      }
      
      .logo {
        display: block;
        width: 80px;
        height: auto;
      }
      
      .logo-box {
        display: flex;
        align-items: center;
        height: 13.2px;
        
        .f-logo {
          display: block;
          width: 5px;
          height: auto;
          border-radius: 1.7px;
          margin: 0 2.4px;
        }
        .shipinhao-icon {
          
          .shipinhao-qrcode {
            display: none;
            top: 7px;
            right: -10px;
            z-index: 20;
            width: 50px;
            height: auto;
            
            &:hover {
              display: block;
            }
          }
          
          &:hover {
            .shipinhao-qrcode {
              display: block;
            }
          }
        }
      }
      
    }
    
    .date {
      font-size: 5px;
      padding: 3.5px 0;
      border-bottom: 0.1px solid #eee;
    }
    
    .menu-bar {
      
      .menu-bar-content {
        border-bottom: 0.1px solid #eee;
      }
      .menu-container {
        width: 220px;
        margin: 0 auto;
        
        .main-menu {
          width: 100%;
          
          .menu-item {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            width: 39px;
            height: 8px;
            font-size: 3px;
            line-height: 8px;
            font-weight: bold;
            border-radius: 4Px;
            cursor: pointer;
            margin: 2px 2.5px;
            color: #060211;
            font-family: 'Poppins-Bold';
            
            .down-icon {
              width: 6px;
              height: auto;
            }
            
            &.active {
              // background-color: #060211;
              // color: #fff;
              background-color: #F8F8F8;
              color: #060211;
            }
            
            &:hover {
              background-color: #F8F8F8;
              color: #060211;
            }
          }
          
        }
        
        .sub-menu-box {
          display: none;
          width: 100%;
          margin: 0 auto;
          box-shadow: 0 3Px  6Px rgba(0, 0, 0, .2);
          border-radius: 0 0 4Px 4Px;
          z-index: 10;
          
          .sub-menu {
            width: 39px;
            padding: 1.5px 0 5px;
            margin: 0 2.5px;
            
            &.sub-menu1 {
              width: 45px;
            }
            
            .sub-menu-item {
              display: block;
              width: 100%;
              font-size: 2.8px;
              line-height: 3.6em;
              cursor: pointer;
              color: #060211;
              font-family: 'Poppins-Medium','Poppins-Medium';
              
              &:hover {
                background-color: #00ABA5;
                color: #fff;
                border-radius: 3Px;
                
                a {
                  color: #fff;
                }
              }
              
              a {
                color: #060211;
              }
              
            }
          
            .sub-menu-item-en {
              display: block;
              width: 100%;
              font-size: 2.8px;
              line-height: 3.6em;
              cursor: pointer;
              color: #060211;
              text-align: left;
              padding-left: 3px;
              font-family: 'Poppins-Light';
              
              &:hover {
                font-family: 'Poppins-Bold';
              }
              
              a {
                color: #060211;
              }
              
              
            }
          
          }
          
        }
        
        &:hover {
          .sub-menu-box {
            display: flex;
          }
        }
      }
      
      .search-bar {
        position: absolute;
        z-index: 1;
        top: 0;
        right: 10px;
        height: 12px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0 5px;
        
        .search-input {
          width: 0;
          height: 8px;
          margin: 2px;
          padding: 0 0 0 2px;
          border-radius: 1px;
          background-color: #fff;
          border: none;
          font-size: 2.8px;
          transition: 0.5s width;
          outline: none;
          
          // &::placeholder {
          //   font-size: 2.8px;
          // }
        }
        
        &:hover {
          .search-input {
            width: 51px;
            background-color: #F8F8F8;
          }
        }
        
        .search-icon {
          width: 4px;
          height: auto;
          cursor: pointer;
        }
      }
      
    }
  }
  
</style>