
import {service} from './request.js';
// import qs from 'qs';

// 上传护照或通行证
// export function uploadPassport(base64, type) {
// 	return service({
// 		url: '/h5/idCard/image/upload/base64?idCardType=' + type,
// 		data: base64,
//     isShowNotify: true,
// 	})
// }

// 获取国家地区列表
export function getCountryList() {
	return service({
		url: 'https://reg40.szcreativeweek.com/h5/idd/code',
    method: 'post',
    headers: {
      'content-type': 'application/json;charset=UTF-8',
      tx: 'tx'
    },
		data: {}
	})
}

// 根据类型获取banner
export function getHomeBanner(params) {
	return service({
		url: '/design/v1/app/common/banner',
    method: 'get',
    params
	})
}

// 获取首页展商品类列表
export function getExhibitionCategoryList() {
	return service({
		url: '/design/v1/app/common/exhibitionCategory',
    method: 'get',
	})
}

// 获取往届概述
export function getExhibitionRecords() {
	return service({
		url: '/design/v1/app/common/exhibitionRecords',
    method: 'get',
	})
}

// 获取展馆列表
export function getPavilionList(categoryId) {
	return service({
		url: '/design/v1/app/common/pavilion?categoryId=' + categoryId,
    method: 'get',
	})
}

// 根据品类获取展商列表
export function getCategoryList(params) {
	return service({
		url: '/design/v1/app/common/category',
    method: 'get',
		params
	})
}
// 根据品类、展馆获取展商列表
export function getExhibitorByCategory(params) {
	return service({
		url: '/design/v1/app/exhibitor',
    method: 'get',
		params
	})
}

// 获取展商详情
export function getExhibitorDetail(id) {
	return service({
		url: '/design/v1/app/exhibitor/' + id,
    method: 'get',
	})
}

// 获取展会新闻资讯列表
export function getNewsList(params) {
	return service({
		url: '/design/v1/app/common/news',
    method: 'get',
    params
	})
}
// 获取展会新闻资讯详情
export function getNewsDetail(id) {
	return service({
		url: '/design/v1/app/common/news/' + id,
    method: 'get',
	})
}

// 获取品牌资讯详情（展商优推荐文章）
export function getArticleDetail(id) {
	return service({
		url: `/design/v1/app/article/${id}?preview=false`,
    method: 'get',
	})
}

// 获取主题展列表
export function getThemeList() {
	return service({
		url: '/design/v1/app/common/partsOfficial',
    method: 'get',
	})
}

// 获取视频&图片列表
export function getSessionList() {
	return service({
		url: '/design/v1/app/common/session',
    method: 'get',
	})
}

// 获取视频&图片资源列表
export function getSessionDetailList(params) {
	return service({
		url: '/design/v1/app/common/sessionDetail',
    method: 'get',
    params
	})
}

// 获取友情链接列表
export function getExternalList() {
	return service({
		url: '/design/v1/app/common/external',
    method: 'get',
	})
}

// 采购表单提交
export function submitSourcing(data) {
	return service({
		url: '/design/v1/app/exhibitor/procure',
    method: 'post',
    data
	})
}
// 团体表单提交
export function procureGroup(data) {
	return service({
		url: '/design/v1/app/exhibitor/procureGroup',
    method: 'post',
    data
	})
}

